import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import AssetCont from "../../../components/GatedAsset/GatedAsset";
import { gatedSections } from "../../../data/landing/gated-v2";
import GatedStyles from "../../../components/GatedAsset/GatedStyles";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import { NewBusinessTypes } from "../../../data/common-data";
import {
  formItemVisibilityEventName,
  zipValidationRegExp,
} from "../../../constants";
// import LargeFeaturesV1_5 from "../../../components/CustomGated/LargeFeaturesV1-5";
// import WhyHeroBg from "../../../components/_NewAnimated/NewWhyHero/WhyHeroBg";
// import GatedForm from "../../../components/CustomGated/GatedForm";

const LargeFeaturesV1_5 = loadable(() =>
  import("../../../components/CustomGated/LargeFeaturesV1-5")
);
const WhyHeroBg = loadable(() =>
  import("../../../components/_NewAnimated/NewWhyHero/WhyHeroBg")
);
const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);

const formDataToSend = ["name", "email", "phone", "company", "get_demo"];
const shortFormDataToSend = ["name", "email", "phone", "company", "get_demo"];
const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. yourname@gmail.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness LLC ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.DROPDOWN,
    itemSubType: null,
    label: "Industry",
    name: "industry",
    rules: [
      {
        required: true,
        message: "Please enter the type of the project",
      },
    ],
    placeholder: "Select your industry",
    options: NewBusinessTypes,
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv2 = () => {
  const formHandler = (props) => {
    console.log(props);
  };

  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Gated Assets | Point-of-Sale E-Book"
        description="Boost efficiency, increase sales, and keep guests coming back with a single point-of-sale solution for in-person orders, Order with Google, and online ordering"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row gutter={[32, 32]}>
        <Col lg={12}>
          <div className="gate-heading">
            <Heading injectionType={2} level={1} symbol="." withBlueSymbol>
              Deepening customer relationships with a loyalty program
            </Heading>
            <p className="gated-intro-para ">
              Restaurant owners have long recognized the value of creating and
              deepening customer relationships and fostering loyalty. Learn how
              to increase loyalty of your guests and drive repeat visits with
              our free whitepaper.
            </p>
          </div>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <LargeFeaturesV1_5 sectionData={gatedSections} />
        </Col>
        <Col lg={12}>
          <WhyHeroBg
            imageName="background.png"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="gated-form-cont">
              <GatedForm
                pageTitle="Title of the downloadable content"
                pageSubtext={subtext}
                formTitle="Download your free whitepaper."
                formItems={data}
                formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
                formDataToSend={formDataToSend}
                shortFormDataToSend={shortFormDataToSend}
                featureTitle="What you get"
                featureSubtext="Fill out the form to get your free download today."
                thankYouPath="/gated-assets/loyalty-program-customer-relationship/thank-you"
                gatedCampaign="loyalty-program-customer-relationship"
                blockList={blockList}
                onFormSubmit={formHandler}
                withLineOfBusinessOption
              />
            </div>
          </WhyHeroBg>
        </Col>
      </Row>
    </LpLayout>
  );
};

export default GatedAssetv2;
