import React from 'react';
import { colors, viewports } from '../../style-vars';

const ArticlesStyles = () => (
  <style jsx>{`
    .asset-form-wrapper {
      position: relative;
      background: ${colors.white};
      padding: 32px 16px;
      border-radius: 16px;
      box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
      width: 90%;
      margin: 0 auto;
    }
    .asset-form-wrapper h2 {
      width: 80%;
      margin: 0 auto 40px auto;
    }
    .asset-intros {
      width: 80%;
    }
    .asset-p {
      color: #748093;
      font-size: 18px !important;
    }
    .asset-ul {
      list-style: none;
      padding: 0;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .asset-ul li {
      margin: 18px 0;
      display: flex;
      align-items: center;
    }
    .asset-ul li img {
      margin-right: 12px;
    }
    .asset-ul li p {
      font-size: 16px;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 0;
    }
    .asset-features {
      transform: translateY(-25%);
    }
    @media ${viewports.mdOrSmaller} {
      .asset-intros {
        width: 100%;
      }
      .asset-features {
        margin-top: 40px;
        transform: translateY(0);
      }
    }

    @media ${viewports.smOrSmaller} {
      .asset-form-wrapper {
        width: 100%;
        margin-top: 40px;
      }
    }
  `}</style>
);

export default ArticlesStyles;
